import "./styles.css";
import ReactCountryFlag from "react-country-flag";
import { Wrap, WrapItem, Tag, Tooltip } from "@chakra-ui/react";
import { VisitedCountriesProps } from "./types";

const visitedCountries = [
  ["Uzbekistan", "UZ"],
  ["Malaysia", "MY"],
  ["Singapore", "SG"],
  ["Kazakhstan", "KZ"],
  ["United States", "US"],
  ["Canada", "CA"],
  ["Morocco", "MA"],
  ["Spain", "ES"],
  ["Mexico", "MX"],
  ["South Korea", "KR"],
  ["Vietnam", "VN"],
  ["Guatemala", "GT"],
  ["Russia", "RU"],
  ["North Korea", "KP"],
  ["China", "CN"],
  ["Brazil", "BR"],
  ["Australia", "AU"],
  ["United Kingdom", "GB"],
  ["Bahamas", "BS"],
  ["Japan", "JP"],
  ["Egypt", "EG"],
  ["New Zealand", "NZ"],
  ["Thailand", "TH"],
  ["Myanmar", "MM"],
  ["France", "FR"],
  ["Germany", "DE"],
  ["South Africa", "ZA"],
  ["Nigeria", "NG"],
  ["Jamaica", "JM"],
  ["Ecuador", "EC"],
  ["Turkey", "TR"],
  ["Czechia", "CZ"],
  ["Peru", "PE"],
  ["Belgium", "BE"],
  ["Monaco", "MC"],
  ["Italy", "IT"],
];

const VisitedCountriesFlags: React.FC<VisitedCountriesProps> = ({
  hoveredCountry,
  setHoveredCountry,
}) => {
  const hoveredCountryName = hoveredCountry.country;
  return (
    <Wrap spacing={3} justify="center" padding={4}>
      {visitedCountries.map((country) => (
        <WrapItem key={country[1]}>
          <Tooltip label={country[0]} fontSize="md" hasArrow>
            <Tag
              // size={hoveredCountry === country[0] ? "lg" : "sm"}
              size="md"
              variant="subtle"
              backgroundColor={
                hoveredCountryName === country[0] ? "#0693E3" : "#8743e2"
              }
              color="white"
              onMouseOver={() => {
                if (hoveredCountryName !== country[0])
                  setHoveredCountry({ country: country[0], source: "list" });
              }}
              onMouseOut={() => {
                if (hoveredCountryName === country[0])
                  setHoveredCountry({ country: null, source: "list" });
              }}
              transform={
                hoveredCountry.source === "map" &&
                hoveredCountryName === country[0]
                  ? "scale(1.3)"
                  : "scale(1)"
              }
              // transition="transform 0.2s ease-in-out"
              userSelect="none" // Prevents text highlighting
            >
              {/* {country[0]} */}
              <ReactCountryFlag
                countryCode={country[1]}
                style={{ pointerEvents: "none" }} // Makes the flag ignore mouse events
              />
            </Tag>
          </Tooltip>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default VisitedCountriesFlags;
