import React from "react";
import VisitedCountriesMap from "../visited-countries-map/VisitedCountriesMap";
import VisitedCountriesFlags from "../visited-countries-map/VisitedCountriesFlags";
import "./Home.css";
import { Button, HStack, WrapItem, Link, Box } from "@chakra-ui/react";
import { FaInstagram, FaLinkedin, FaStrava } from "react-icons/fa";
import { useState } from "react";

import { HoveredCountry } from "../visited-countries-map/types";

function Home() {
  const [hoveredCountry, setHoveredCountry] = useState<HoveredCountry>({
    country: null,
    source: "home",
  });

  return (
    <div className="Home">
      <header className="Home-header">
        <p style={{ padding: "10px" }}>Welcome to Miraziz's website!</p>
      </header>
      <div>
        <p />
        <HStack justifyContent="center" alignItems="center">
          <WrapItem>
            <Link
              href="https://linkedin.com/in/miraziz"
              style={{ textDecoration: "none", paddingTop: "10px" }}
              isExternal
            >
              <Button colorScheme="linkedin" leftIcon={<FaLinkedin />}>
                LinkedIn
              </Button>
            </Link>
          </WrapItem>
          <WrapItem>
            <Link
              href="https://www.instagram.com/miraziztravel"
              style={{ textDecoration: "none", paddingTop: "10px" }}
              isExternal
            >
              <Button colorScheme="yellow" leftIcon={<FaInstagram />}>
                Instagram
              </Button>
            </Link>
          </WrapItem>
          <WrapItem>
            <Link
              href="https://www.strava.com/athletes/31277670"
              style={{ textDecoration: "none", paddingTop: "10px" }}
              isExternal
            >
              <Button colorScheme="orange" leftIcon={<FaStrava />}>
                Strava
              </Button>
            </Link>
          </WrapItem>
        </HStack>
      </div>
      {/* <div style={{ alignItems: "center" }}> */}

      <Box>
        <VisitedCountriesMap
          hoveredCountry={hoveredCountry}
          setHoveredCountry={setHoveredCountry}
        />
        <VisitedCountriesFlags
          hoveredCountry={hoveredCountry}
          setHoveredCountry={setHoveredCountry}
        />
      </Box>
      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <VisitedCountriesMap />
        <span style={{ padding: 10 }}>
          <VisitedCountriesFlags />
        </span>
      </div> */}
    </div>
  );
}

export default Home;
