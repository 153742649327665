import "./styles.css";
import { chakra } from "@chakra-ui/system";
import { Tooltip } from "@chakra-ui/react";
import { VisitedCountriesProps } from "./types";

const reactSimpleMaps = require("react-simple-maps");
const { ComposableMap, Geographies, Geography } = reactSimpleMaps;

const ChakraGeography = chakra(Geography);

const visitedCountries = new Set([
  "UZB",
  "MYS",
  "SGP",
  "KAZ",
  "USA",
  "CAN",
  "MAR",
  "ESP",
  "MEX",
  "KOR",
  "VNM",
  "GTM",
  "RUS",
  "PRK",
  "CHN",
  "BRA",
  "AUS",
  "GBR",
  "BHS",
  "JPN",
  "EGY",
  "NZL",
  "THA",
  "MMR",
  "FRA",
  "DEU",
  "ZAF",
  "NGA",
  "JAM",
  "ECU",
  "TUR",
  "CZE",
  "PER",
  "BEL",
  "MON",
  "ITA",
]);

const VisitedCountriesMap: React.FC<VisitedCountriesProps> = ({
  hoveredCountry,
  setHoveredCountry,
}) => {
  const hoveredCountryName = hoveredCountry.country;
  return (
    <ComposableMap>
      <Geographies geography="/ne_110m_coastline.geojson">
        {({ geographies }: { geographies: any }) =>
          geographies.map((geo: any) => {
            return (
              <ChakraGeography
                key={geo.rsmKey}
                geography={geo}
                stroke-width="3"
                fill-opacity="0%"
                stroke="black"
              />
            );
          })
        }
      </Geographies>
      <Geographies geography="/country_geographies.json">
        {({ geographies }: { geographies: any }) =>
          geographies.map((geo: any) => {
            const countryId = geo.properties.name;
            const haveVisited = visitedCountries.has(geo.id);
            const isHovered = hoveredCountryName === geo.properties.name;
            let countryColor;
            if (isHovered && haveVisited) {
              countryColor = "#0693E3";
            } else if (isHovered) {
              countryColor = "pink";
            } else if (haveVisited) {
              countryColor = "#8743e2";
            } else {
              countryColor = "white";
            }

            return (
              <Tooltip label={geo.properties.name} placement="right-end">
                <ChakraGeography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={countryColor}
                  stroke-width="1"
                  stroke="white"
                  _hover={{ fill: haveVisited ? "#0693E3" : "pink" }}
                  // onMouseEnter={() => setHoveredCountry(geo.properties.name)}
                  // onMouseLeave={() => setHoveredCountry(null)}
                  onMouseOver={() => {
                    if (hoveredCountryName !== countryId)
                      setHoveredCountry({
                        country: countryId,
                        source: "map",
                      });
                  }}
                  onMouseOut={() => {
                    if (hoveredCountryName === countryId)
                      setHoveredCountry({ country: null, source: "map" });
                  }}
                />
              </Tooltip>
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
};

export default VisitedCountriesMap;
